import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import store from './redux/store';
import "./styles/app.scss";
import AppRoutes from "./routes/AppRoutes";
import { ProgressBarProvider } from './context/ProgressBarContext';
import ChatWidget from './components/callbell/ChatWidget';


function App() {
  return (
    <Provider store={store}>
      <ProgressBarProvider>
        <AppRoutes />        
      </ProgressBarProvider>
    </Provider>
  );
}

export default App;
