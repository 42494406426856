export const getStatusDocs = (documento = {}) => {
    const status = documento?.sStatus ? documento.sStatus : "";
    return status.toString().toUpperCase();
}

/**
 * Valida si el correo es valido o no.
 * @author ERL 2023-11-02 06:21 pm
 * @param {String} email correo a validar
 * @return boolean
 */
export const isEmail = (email) => {
    var expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (expr.test(email)) {
        return true;
    } else {
        return false;
    }
}

/**
     * @author ERL 2023-11-02 04:34 pm
     * Valida si el RFC es valido, ademas de aceptar RFC generico para pruebas
     * @param {String} rfc 
     * @param {Boolean} aceptarGenerico 
     * @returns boolean
     */
export const validaRFC = (rfc, aceptarGenerico = true) => {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let validado = rfc.match(re);
    if (!validado)
        return false;
    const digitoVerificador = validado.pop(),
        rfcSinDigito = validado.slice(1).join(''),
        len = rfcSinDigito.length,
        diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice = len + 1;
    let suma,
        digitoEsperado;

    if (len == 12) suma = 0
    else suma = 481;
    for (let i = 0; i < len; i++)
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - suma % 11;
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";
    if ((digitoVerificador != digitoEsperado) &&
        (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
        return false;
    else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
        return false;
    return true;
}

  /**
   * Formatea un monto a pesos mexicanos'.
   * @author ERL 2025-01-23 02:18 pm
   * @param {string} nMonto - Monto.
   * @returns {string}
   */
  export const formatToMexicanPesos = (nMonto) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(nMonto)
  }